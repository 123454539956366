@mixin fontTeko($fontSize : 16px, $fontWeight : normal, $fontStyle : normal, $fontStretch : normal, $letterSpacing : normal) {
  font-family: Teko;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  font-stretch: $fontStretch;
  letter-spacing: $letterSpacing;
}

@mixin fontRoboto($fontSize : 16px, $fontWeight : normal, $fontStyle : normal, $fontStretch : normal, $letterSpacing : normal) {
  font-family: Roboto;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  font-stretch: $fontStretch;
  letter-spacing: $letterSpacing;
}

@mixin fontSmoothing($moz : grayscale, $webkit : antialiased) {
  -moz-osx-font-smoothing: $moz;
  -webkit-font-smoothing: $webkit;
}

@mixin backgroundImage($url, $repeat : no-repeat, $position : center) {
  background-image: $url;
  background-repeat: $repeat;
  background-position: $position;
}

$color-primary: #00AEEF;
$color-primary-dark: #0D87E9;
$color-primary-light: #D3EAFE;
$color-secondary: #FAA61A;
$color-secondary-dark: #DE7F1E;
$color-secondary-light: #FFF6E6;
$color-danger: #AF0E0E;
$color-success: #22AC47;
$color-dark: #131516;
$color-dark-gray: #555F60;
$color-medium-gray: #8C979A;
$color-light-gray: #DADEDF;
$color-lighter-gray: #F2F3F4;
$color-super-light-gray: #FCFCFC;
$color-gray: #C1C7C9;
$color-white: #FFFFFF;

$image-icon-check: url(/static/images/icons/check.svg);
$image-icon-warning: url(/static/images/icons/warning.svg);
$image-spinner: url(/static/images/spinner.gif);
$image-add-photo-asset: url(/static/images/icons/add-photo-asset.svg);


body {
  @include fontRoboto();
  @include fontSmoothing();
  color: $color-dark-gray;
  background-color: $color-white;
}

p {
  &.lead {
    color: #4D4D4D;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: 0.1px;
  }
}

a {
  color: $color-primary;
  text-decoration: underline;
}

ul {
  margin-left: 17px;
  padding-left: 0;
}

// Background colors
.bg-primary {
  background-color: $color-primary !important;
}

.bg-secondary {
  background-color: $color-secondary !important;
}

.bg-error {
  background-color: $color-danger !important;
}

.bg-success {
  background-color: $color-success !important;
}

.bg-dark-blue {
  background-color: #0D87E9;
}

.bg-dark-orange {
  background-color: #DE7F1E;
}

.bg-light-blue {
  background-color: #D3EAFE;
}

.bg-light-orange {
  background-color: #FFF6E6;
}

.bg-dark {
  background-color: $color-dark !important;
}

.bg-dark-gray {
  background-color: $color-dark-gray;
}

.bg-medium-gray {
  background-color: $color-medium-gray;
}

.bg-gray {
  background-color: $color-gray;
}

.bg-light-gray {
  background-color: $color-light-gray;
}

.bg-lighter-gray {
  background-color: $color-lighter-gray;
}

.bg-super-light-gray {
  background-color: $color-super-light-gray;
}

// text colors
.text-danger {
  color: $color-danger;
}

.underline-primary {
  background-image: linear-gradient(to right, $color-primary 100%);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto 2px;
}

.label {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: $color-dark-gray;
}

.value {
  line-height: 1.19;
  color: $color-dark;
  margin-bottom: 30px;
}

.vp-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  background-color: rgba(50, 50, 50, 0.5);
}

.full-width {
  width: 100%;
}

// Modals
.modal {
  &-backdrop {
    &.show {
      background-color: #323232;
      opacity: .5;
    }
  }

  &-content {
    .modal {
      &-body {
        padding-top: 4px;
      }

      &-header {
        padding-top: 19px;
      }
    }
  }

  &-body {
    letter-spacing: .2px;
  }

  &-footer {
    display: inline-block;

    button {
      float: right;
    }
  }

  &-title {
    color: $color-dark;
    font-size: 20px;
    font-weight: 500;
  }
}

.fade {
  transition: opacity .3s linear;
}

.loading {
    background: rgba(255, 255, 255, .5) $image-spinner center center no-repeat;
    bottom: 0;
    left: 0;
    top: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: table;

    &-text {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        padding-top: 90px;

        & > span {
            background: white;
            padding: 8px;
            border-radius: 1px;
        }
    }
}

.acquire-image {
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin: 0 8px 4px 0;
    background: transparent $image-add-photo-asset no-repeat 0 0;
    background-size: 100px;
    position: relative;

    .loading {
        position: absolute;
    }
}
