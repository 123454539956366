// Buttons
.btn {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1.25;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 13px 30px;
  margin-bottom: 30px;

  &:disabled,
  &.disabled {
    pointer-events: none;
  }

  &.btn-primary {
    background-color: $color-primary;
    border-color: $color-white;
    color: $color-white;

    &:active,
    &:active:focus,
    &:hover,
    &:focus {
      background-color: $color-primary-dark;
      border-color: $color-white;
      color: $color-white;
    }

    &:disabled {
      background-color: $color-primary-light;
      border-color: $color-white;
      color: $color-primary;
    }
  }

  &.btn-outline-primary {
    background-color: $color-white;
    border: 1px solid $color-primary;
    border-color: $color-primary;
    color: $color-dark;
    padding: 12px 30px;

    &:hover {
      background-color: $color-primary-light;
      border-color: $color-primary;
      color: $color-dark;
    }
  }

  &.btn-secondary {
    background-color: $color-secondary;
    border-color: $color-white;
    color: $color-white;
    &:hover {
      background-color: $color-secondary-dark;
      border-color: $color-white;
      color: $color-white;
    }

    &:disabled {
      background-color: $color-secondary-light;
      border-color: $color-white;
      color: $color-secondary;
    }
  }

  &.btn-outline-secondary {
    background-color: $color-white;
    border: 1px solid $color-secondary;
    border-color: $color-secondary;
    color: $color-dark;
    padding: 12px 30px;

    &:hover {
      background-color: $color-secondary-light;
      border-color: $color-secondary;
      color: $color-dark;
    }
  }

  &.btn-dark {
    &:disabled {
      background-color: $color-light-gray;
      border-color: $color-medium-gray;
      color: $color-medium-gray;
    }
  }

  &.btn-outline-dark {
    background-color: $color-white;
    border: 1px solid $color-dark;
    border-color: $color-dark;
    color: $color-dark;
    padding: 12px 30px;

    &:hover {
      background-color: $color-lighter-gray;
      border-color: $color-dark;
      color: $color-dark;
    }
    &:disabled {
      background-color: $color-white;
      border-color: $color-light-gray;
      color: $color-medium-gray;
    }
  }

  &.btn-sm {
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.3px;
    border-radius: 4px;
    padding: 8px 20px;
  }

  &.btn-tertiary {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: condensed;
    line-height: 1.25;
    letter-spacing: 0;
    color: $color-medium-gray;

    &:disabled {
      color: $color-light-gray;
    }

    &:active,
    &:active:focus,
    &:focus,
    &:hover {
      color: $color-dark;
      background-color: transparent;
      border: none;

      .zamicon {
        color: $color-primary;
      }
    }
  }

  &.btn-tertiary-primary {
    color: $color-primary;

    &:hover {
      color: $color-primary-dark;

      .zamicon {
        color: $color-primary-dark;
      }
    }
  }

  &.icon-left {
    padding-left: 64px;

    .zamicon {
      position: absolute;
      left: 30px;
      top: 11px;
    }
  }

  .zamicon {
    &:before {
      color: inherit;
    }
  }
}

.btn-group {
  &.show {
    .btn {
      background-color: $color-primary-dark;
      border-color: $color-white;
      color: $color-white;
    }
  }

  .dropdown-menu {
    background-color: #FAFAFA;

    a {
      text-decoration: none;
    }
  }

  .dropdown-item {
    color: $color-dark;
    line-height: 1.71;
    letter-spacing: 0.2px;
    min-width: 20rem;
    max-width: 25rem;

    &:hover {
      background-color: #EEEEEE;
    }

    .zamicon {
      padding-right: 10px;
    }
  }
}

.btn-group {
  margin: 0;
}

.multiple-state {
  width: 100%;

  &.btn-group {
    .btn {
      text-transform: none;

      &.btn-primary {
        &:disabled {
          background-color: $color-gray;
          color: $color-white;
        }
      }

      &.btn-outline-primary {
        color: $color-primary;

        &:disabled {
          color: $color-gray;
          border: 1px solid $color-gray;
        }
      }

      &:not(:first-child) {
        border-left: none;
      }
    }
  }
}
