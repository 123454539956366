// Navigations
.nav {
  &-content {
    padding: 23px 0;
  }

  &-item {
    margin-bottom: 8px;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: .3px;
    color: $color-dark;

    .zamicon {
      padding-right: 30px;
    }
  }

  &bar-header {
    border-bottom: 1px solid $color-light-gray;
    padding: 16px;

    &__body {
      font-size: 14px;
      line-height: .93;
      letter-spacing: normal;
      color: $color-dark;
    }

    &__title {
      color: $color-dark;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.bmd-layout {
  &-backdrop {
    background-color: rgba(50, 50, 50, 0.5);
  }

  &-container {
    & > .bmd-layout-drawer {
      width: 300px;
      transform: translateX(-310px);
    }
  }

  &-drawer {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    & > header {
      .navbar-brand {
        padding-bottom: 25px;
        padding-left: 0;
      }
    }
  }
}
