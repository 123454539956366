@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url(/static/fonts/roboto-v16-latin-regular.eot);
  src: local("Roboto Regular"), local("Roboto-Regular"), url(/static/fonts/roboto-v16-latin-regular.eot?#iefix) format("embedded-opentype"), url(/static/fonts/roboto-v16-latin-regular.woff2) format("woff2"), url(/static/fonts/roboto-v16-latin-regular.woff) format("woff"), url(/static/fonts/roboto-v16-latin-regular.ttf) format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url(/static/fonts/roboto-v16-latin-italic.eot);
  src: local("Roboto Italic"), local("Roboto-Italic"), url(/static/fonts/roboto-v16-latin-italic.eot?#iefix) format("embedded-opentype"), url(/static/fonts/roboto-v16-latin-italic.woff2) format("woff2"), url(/static/fonts/roboto-v16-latin-italic.woff) format("woff"), url(/static/fonts/roboto-v16-latin-italic.ttf) format("truetype");
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url(/static/fonts/roboto-v16-latin-500.eot);
  src: local("Roboto Bold"), local("Roboto-Bold"), url(/static/fonts/roboto-v16-latin-500.eot?#iefix) format("embedded-opentype"), url(/static/fonts/roboto-v16-latin-500.woff2) format("woff2"), url(/static/fonts/roboto-v16-latin-500.woff) format("woff"), url(/static/fonts/roboto-v16-latin-500.ttf) format("truetype");
}

@font-face {
  font-family: Teko;
  font-style: normal;
  font-weight: 400;
  src: url(/static/fonts/teko-v6-latin-regular.eot);
  src: local("Teko Regular"), local("Teko-Regular"), url(/static/fonts/teko-v6-latin-regular.eot?#iefix) format("embedded-opentype"), url(/static/fonts/teko-v6-latin-regular.woff2) format("woff2"), url(/static/fonts/teko-v6-latin-regular.woff) format("woff"), url(/static/fonts/teko-v6-latin-regular.ttf) format("truetype");
}

@font-face {
  font-family: Teko;
  font-style: normal;
  font-weight: 500;
  src: url(/static/fonts/teko_medium-md-webfont.eot);
  src: local("Teko Medium"), local("Teko-Medium"), url(/static/fonts/teko_medium-md-webfont.eot?#iefix) format("embedded-opentype"), url(/static/fonts/teko_medium-md-webfont.woff2) format("woff2"), url(/static/fonts/teko_medium-md-webfont.woff) format("woff"), url(/static/fonts/teko_medium-md-webfont.ttf) format("truetype");
}

@font-face {
  font-family: Teko;
  font-style: normal;
  font-weight: 100;
  src: url(/static/fonts/Teko-Light.eot);
  src: local("Teko Light"), local("Teko-Light"), url(/static/fonts/Teko-Light.eot?#iefix) format("embedded-opentype"), url(/static/fonts/Teko-Light.woff2) format("woff2"), url(/static/fonts/Teko-Light.woff) format("woff"), url(/static/fonts/Teko-Light.ttf) format("truetype");
}

@font-face {
  font-family: Zamicons;
  src: url(/static/fonts/Zamicons.eot?4cfyb8);
  src: url(/static/fonts/Zamicons.eot?4cfyb8#iefix) format("embedded-opentype"), url(/static/fonts/Zamicons.ttf?4cfyb8) format("truetype"), url(/static/fonts/Zamicons.woff?4cfyb8) format("woff"), url(/static/fonts/Zamicons.svg?4cfyb8#Zamicons) format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="zamicon-"], [class*=" zamicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: Zamicons !important;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zamicon-arrow-drop-down:before {
  content: "\e900";
  color: #555F60;
}

.zamicon-arrow-drop-up:before {
  content: "\e901";
  color: #555F60;
}

.zamicon-back:before {
  content: "\e902";
  color: #555F60;
}

.zamicon-calendar:before {
  content: "\e903";
  color: #555F60;
}

.zamicon-camera-success:before {
  content: "\e904";
  color: #555F60;
}

.zamicon-camera-switch:before {
  content: "\e905";
  color: #555F60;
}

.zamicon-camera:before {
  content: "\e906";
  color: #555F60;
}

.zamicon-cancel-in-circle:before {
  content: "\e907";
  color: #555F60;
}

.zamicon-cancel:before {
  content: "\e908";
  color: #555F60;
}

.zamicon-caret-sort:before {
  content: "\e925";
  color: #555F60;
}

.zamicon-delete:before {
  content: "\e909";
  color: #555F60;
}

.zamicon-download:before {
  content: "\e90a";
  color: #555F60;
}

.zamicon-edit:before {
  content: "\e90b";
  color: #555F60;
}

.zamicon-error:before {
  content: "\e90c";
  color: #555F60;
}

.zamicon-filter:before {
  content: "\e90d";
  color: #555F60;
}

.zamicon-flash:before {
  content: "\e90e";
  color: #555F60;
}

.zamicon-forum:before {
  content: "\e90f";
  color: #555F60;
}

.zamicon-gallery:before {
  content: "\e910";
  color: #555F60;
}

.zamicon-gift:before {
  content: "\e911";
  color: #555F60;
}

.zamicon-hamburger:before {
  content: "\e912";
  color: #555F60;
}

.zamicon-heart:before {
  content: "\e913";
  color: #555F60;
}

.zamicon-inactive-user:before {
  content: "\e914";
  color: #555F60;
}

.zamicon-location-off:before {
  content: "\e915";
  color: #555F60;
}

.zamicon-location:before {
  content: "\e916";
  color: #555F60;
}

.zamicon-logout:before {
  content: "\e917";
  color: #555F60;
}

.zamicon-mic:before {
  content: "\e918";
  color: #555F60;
}

.zamicon-overview:before {
  content: "\e919";
  color: #555F60;
}

.zamicon-password-hide:before {
  content: "\e91a";
  color: #555F60;
}

.zamicon-password-show:before {
  content: "\e91b";
  color: #555F60;
}

.zamicon-plus:before {
  content: "\e91c";
  color: #555F60;
}

.zamicon-scan:before {
  content: "\e91d";
  color: #555F60;
}

.zamicon-search:before {
  content: "\e91e";
  color: #555F60;
}

.zamicon-settings:before {
  content: "\e91f";
  color: #555F60;
}

.zamicon-shopping-cart:before {
  content: "\e920";
  color: #555F60;
}

.zamicon-success:before {
  content: "\e921";
  color: #555F60;
}

.zamicon-table:before {
  content: "\e922";
  color: #555F60;
}

.zamicon-three-dots:before {
  content: "\e923";
  color: #555F60;
}

.zamicon-upload:before {
  content: "\e924";
  color: #555F60;
}

body {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #555F60;
  background-color: #FFFFFF;
}

p.lead {
  color: #4D4D4D;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.1px;
}

a {
  color: #00AEEF;
  text-decoration: underline;
}

ul {
  margin-left: 17px;
  padding-left: 0;
}

.bg-primary {
  background-color: #00AEEF !important;
}

.bg-secondary {
  background-color: #FAA61A !important;
}

.bg-error {
  background-color: #AF0E0E !important;
}

.bg-success {
  background-color: #22AC47 !important;
}

.bg-dark-blue {
  background-color: #0D87E9;
}

.bg-dark-orange {
  background-color: #DE7F1E;
}

.bg-light-blue {
  background-color: #D3EAFE;
}

.bg-light-orange {
  background-color: #FFF6E6;
}

.bg-dark {
  background-color: #131516 !important;
}

.bg-dark-gray {
  background-color: #555F60;
}

.bg-medium-gray {
  background-color: #8C979A;
}

.bg-gray {
  background-color: #C1C7C9;
}

.bg-light-gray {
  background-color: #DADEDF;
}

.bg-lighter-gray {
  background-color: #F2F3F4;
}

.bg-super-light-gray {
  background-color: #FCFCFC;
}

.text-danger {
  color: #AF0E0E;
}

.underline-primary {
  background-image: linear-gradient(to right, #00AEEF 100%);
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto 2px;
}

.label {
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: #555F60;
}

.value {
  line-height: 1.19;
  color: #131516;
  margin-bottom: 30px;
}

.vp-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  background-color: rgba(50, 50, 50, 0.5);
}

.full-width {
  width: 100%;
}

.modal-backdrop.show {
  background-color: #323232;
  opacity: .5;
}

.modal-content .modal-body {
  padding-top: 4px;
}

.modal-content .modal-header {
  padding-top: 19px;
}

.modal-body {
  letter-spacing: .2px;
}

.modal-footer {
  display: inline-block;
}

.modal-footer button {
  float: right;
}

.modal-title {
  color: #131516;
  font-size: 20px;
  font-weight: 500;
}

.fade {
  transition: opacity .3s linear;
}

.loading {
  background: rgba(255, 255, 255, 0.5) url(/static/images/spinner.gif) center center no-repeat;
  bottom: 0;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: table;
}

.loading-text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding-top: 90px;
}

.loading-text > span {
  background: white;
  padding: 8px;
  border-radius: 1px;
}

.acquire-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  margin: 0 8px 4px 0;
  background: transparent url(/static/images/icons/add-photo-asset.svg) no-repeat 0 0;
  background-size: 100px;
  position: relative;
}

.acquire-image .loading {
  position: absolute;
}

.h0,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  padding: 0;
  color: #131516;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.h0 {
  font-family: Teko;
  font-size: 72px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.h0,
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: 500;
  margin: 15px 0;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 10px 0;
}

h5,
.h5,
h6,
.h6 {
  font-weight: bold;
  letter-spacing: 0.1px;
}

.h0,
h2,
.h2 {
  letter-spacing: 0.7px;
  line-height: 1;
}

h1,
.h1 {
  font-size: 46px;
  line-height: 1.57;
  letter-spacing: 0.4px;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 1.13;
  letter-spacing: 0.6px;
}

h2.underline-primary,
.h2.underline-primary {
  padding-bottom: 14px;
  display: inline-block;
}

h3,
.h3 {
  font-size: 24px;
  letter-spacing: 0.5px;
  line-height: normal;
}

h4,
.h4 {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.8px;
}

h5,
.h5 {
  font-size: 12px;
  line-height: 1.67;
}

h6,
.h6 {
  font-size: 10px;
  line-height: 1.6;
}

.table {
  font-size: 14px;
  line-height: 0.93;
}

.table thead th {
  color: #373D3F;
  font-weight: bold;
  background-color: #F2F3F4;
  border-bottom: 2px solid #00AEEF;
  padding: 18px;
}

.table tbody tr:nth-of-type(even) {
  background-color: #F2F3F4;
}

.table tr th {
  font-weight: bold;
}

.table tr th:not(:last-child) {
  border-right: 1px solid #C6C7C7;
}

.table tr th span.zamicon {
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.table td {
  border-left: 1px solid #C6C7C7;
}

.table td, .table th {
  border-top: none;
  padding: 13px;
}

input.form-control,
select.form-control {
  background-color: #F2F3F4;
  border-radius: 4px;
  color: #131516;
  padding: 22px 16px 10px 16px;
}

input.form-control:focus,
select.form-control:focus {
  background-color: #F2F3F4;
  background-image: linear-gradient(0deg, #00AEEF 2px, rgba(0, 150, 136, 0) 0), linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #131516;
  padding-left: 16px;
}

select.form-control:not([size]):not([multiple]) {
  height: 56px;
}

.form-group {
  margin-bottom: 30px;
}

.bmd-form-group {
  padding-top: 0;
}

.bmd-form-group label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: #555F60;
  padding-left: 16px;
}

.bmd-form-group .text-helper {
  position: absolute;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-top: 0;
  padding: 2px 0 0 0;
  color: #555F60;
}

.bmd-form-group .text-helper.disabled {
  color: #8C979A;
}

.bmd-form-group .bmd-help {
  position: absolute;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-top: 0;
  padding: 2px 0 0 0;
}

.bmd-form-group .bmd-label-floating,
.bmd-form-group .bmd-label-placeholder {
  top: 16px;
}

.bmd-form-group .bmd-label-floating.disabled,
.bmd-form-group .bmd-label-placeholder.disabled {
  color: #C1C7C9;
}

.bmd-form-group .form-control {
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.bmd-form-group.is-filled .bmd-help, .bmd-form-group.is-focused .bmd-help {
  color: #00AEEF !important;
}

.bmd-form-group.is-filled .bmd-label-floating,
.bmd-form-group.is-filled .bmd-label-placeholder, .bmd-form-group.is-focused .bmd-label-floating,
.bmd-form-group.is-focused .bmd-label-placeholder {
  top: 8px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.2px;
}

.is-focused [class^='bmd-label'],
.is-focused [class*=' bmd-label'] {
  color: #00AEEF;
}

.form-control {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  background-image: none;
}

.form-control.disabled, .form-control:disabled, .form-control[disabled] {
  background-color: #F2F3F4;
  background-image: none;
  cursor: not-allowed;
}

.is-filled .bmd-label-floating.disabled, .is-filled .bmd-label-floating:disabled, .is-filled .bmd-label-floating[disabled],
.is-filled .bmd-label-placeholder.disabled,
.is-filled .bmd-label-placeholder:disabled,
.is-filled .bmd-label-placeholder[disabled] {
  color: #8C979A;
}

.is-filled .form-control {
  background-image: linear-gradient(0deg, #131516 1px, transparent 0), linear-gradient(0deg, #131516 1px, transparent 0);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.is-filled .form-control.disabled, .is-filled .form-control:disabled, .is-filled .form-control[disabled] {
  background: none;
  background-color: #F2F3F4;
  background-image: linear-gradient(0deg, #8C979A 1px, transparent 0), linear-gradient(0deg, #8C979A 1px, transparent 0);
  cursor: not-allowed;
  color: #C1C7C9;
}

.has-danger [class*=" bmd-label"],
.has-danger [class^=bmd-label] {
  color: #AF0E0E;
}

.has-danger.is-focused .bmd-label-placeholder,
.has-danger.is-focused [class*=" bmd-label"],
.has-danger.is-focused [class^=bmd-label] {
  color: #AF0E0E;
}

.has-danger.is-focused .form-control.is-invalid {
  background-image: linear-gradient(0deg, #AF0E0E 2px, rgba(0, 150, 136, 0) 0), linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
}

.has-danger .is-focused .bmd-label-placeholder,
.has-danger .is-focused [class*=" bmd-label"],
.has-danger .is-focused [class^=bmd-label] {
  color: #AF0E0E;
}

.has-danger .is-focused .form-control.is-invalid {
  background-image: linear-gradient(0deg, #AF0E0E 2px, rgba(0, 150, 136, 0) 0), linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
}

.has-danger .invalid-feedback {
  position: absolute;
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: normal;
  margin-top: 0;
  padding: 2px 0 0 0;
  color: #AF0E0E;
}

.has-danger .form-control.is-invalid {
  background-image: linear-gradient(0deg, #AF0E0E 2px, rgba(0, 150, 136, 0) 0), linear-gradient(0deg, #AF0E0E 1px, transparent 0);
}

.has-danger .form-control.is-invalid:focus {
  box-shadow: none;
}

.no-label .form-control {
  padding: 16px;
}

.is-search.is-focused.is-filled .form-control {
  background-image: none;
}

.is-search.is-focused .form-control {
  box-shadow: 0 1px 2px 0 #DADEDF;
  background-image: none;
}

.is-search.is-focused .form-control:focus {
  background-color: #FFFFFF;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.is-search.is-filled .form-control {
  background-image: none;
  border-radius: 4px;
}

.is-search .form-control {
  background-color: #FFFFFF;
  border: 1px solid #DADEDF;
  padding: 15px 16px;
}

.has-feedback .form-control {
  padding-right: 56px;
}

.has-feedback.is-search .form-control {
  padding-left: 56px;
}

.form-control-feedback {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 2;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  pointer-events: none;
}

.form-control-feedback.zamicon-left {
  left: 18px;
}

.input-group {
  margin-bottom: 30px;
}

.input-group .form-control {
  padding: 12px 16px;
}

.input-group-append {
  background-color: #00AEEF;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.input-group-append .btn {
  margin: 0;
  padding: 12px 14px;
}

.input-group-append .btn .zamicon {
  padding-right: 0;
}

.is-filled:not(.is-focused) .input-group .form-control {
  background-image: none;
}

.is-filled .input-group.is-search .form-control,
.is-focused .input-group.is-search .form-control {
  background-color: #FFFFFF;
  background-image: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.is-focused .input-group.is-search {
  box-shadow: 0 1px 2px 0 #DADEDF;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.md-radio {
  margin: 0 0 30px 0;
}

.md-radio.md-radio-inline {
  display: inline-block;
}

.md-radio input[type="radio"] {
  display: none;
}

.md-radio input[type="radio"]:checked + label:before {
  border-color: #00AEEF;
  animation: ripple 0.2s linear forwards;
}

.md-radio input[type="radio"]:checked + label:after {
  transform: scale(1);
}

.md-radio input[type="radio"]:disabled + label {
  color: #C1C7C9;
  pointer-events: none;
}

.md-radio input[type="radio"]:disabled + label:before {
  border-color: #C1C7C9;
}

.md-radio input[type="radio"]:disabled + label:after {
  background: #C1C7C9;
}

.md-radio label {
  display: inline-block;
  height: 20px;
  position: relative;
  padding: 0 32px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
  line-height: 1.5;
  font-size: 16px;
}

.md-radio label:before, .md-radio label:after {
  position: absolute;
  content: '';
  border-radius: 50%;
  transition: all .3s ease;
  transition-property: transform, border-color;
}

.md-radio label:before {
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

.md-radio label:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  transform: scale(0);
  background: #00AEEF;
}

.md-checkbox {
  position: relative;
  margin: 0 0 30px 0;
  text-align: left;
}

.md-checkbox.md-checkbox-inline {
  display: inline-block;
}

.md-checkbox label {
  cursor: pointer;
  display: inline;
  line-height: 1.5;
  vertical-align: top;
  clear: both;
  padding-left: 1px;
}

.md-checkbox label:not(:empty) {
  padding-left: 12px;
}

.md-checkbox label:before, .md-checkbox label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.md-checkbox label:before {
  width: 1.25em;
  height: 1.25em;
  background: #FFFFFF;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 0.125em;
  cursor: pointer;
  transition: background .3s;
}

.md-checkbox input[type="checkbox"] {
  outline: 0;
  visibility: hidden;
  width: 1.25em;
  margin: 0;
  display: block;
  float: left;
  font-size: inherit;
}

.md-checkbox input[type="checkbox"]:checked + label:before {
  background: #00AEEF;
  border: none;
}

.md-checkbox input[type="checkbox"]:checked + label:after {
  transform: translate(0.25em, 0.3365384615em) rotate(-45deg);
  width: 0.75em;
  height: 0.375em;
  border: 0.125em solid #FFFFFF;
  border-top-style: none;
  border-right-style: none;
}

.md-checkbox input[type="checkbox"]:disabled + label {
  color: #C1C7C9;
  pointer-events: none;
}

.md-checkbox input[type="checkbox"]:disabled + label:before {
  border-color: #C1C7C9;
}

.md-checkbox input[type="checkbox"]:disabled:checked + label:before {
  background: #C1C7C9;
}

.btn {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1.25;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 13px 30px;
  margin-bottom: 30px;
}

.btn:disabled, .btn.disabled {
  pointer-events: none;
}

.btn.btn-primary {
  background-color: #00AEEF;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.btn.btn-primary:active, .btn.btn-primary:active:focus, .btn.btn-primary:hover, .btn.btn-primary:focus {
  background-color: #0D87E9;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.btn.btn-primary:disabled {
  background-color: #D3EAFE;
  border-color: #FFFFFF;
  color: #00AEEF;
}

.btn.btn-outline-primary {
  background-color: #FFFFFF;
  border: 1px solid #00AEEF;
  border-color: #00AEEF;
  color: #131516;
  padding: 12px 30px;
}

.btn.btn-outline-primary:hover {
  background-color: #D3EAFE;
  border-color: #00AEEF;
  color: #131516;
}

.btn.btn-secondary {
  background-color: #FAA61A;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.btn.btn-secondary:hover {
  background-color: #DE7F1E;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.btn.btn-secondary:disabled {
  background-color: #FFF6E6;
  border-color: #FFFFFF;
  color: #FAA61A;
}

.btn.btn-outline-secondary {
  background-color: #FFFFFF;
  border: 1px solid #FAA61A;
  border-color: #FAA61A;
  color: #131516;
  padding: 12px 30px;
}

.btn.btn-outline-secondary:hover {
  background-color: #FFF6E6;
  border-color: #FAA61A;
  color: #131516;
}

.btn.btn-dark:disabled {
  background-color: #DADEDF;
  border-color: #8C979A;
  color: #8C979A;
}

.btn.btn-outline-dark {
  background-color: #FFFFFF;
  border: 1px solid #131516;
  border-color: #131516;
  color: #131516;
  padding: 12px 30px;
}

.btn.btn-outline-dark:hover {
  background-color: #F2F3F4;
  border-color: #131516;
  color: #131516;
}

.btn.btn-outline-dark:disabled {
  background-color: #FFFFFF;
  border-color: #DADEDF;
  color: #8C979A;
}

.btn.btn-sm {
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: 0.3px;
  border-radius: 4px;
  padding: 8px 20px;
}

.btn.btn-tertiary {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.25;
  letter-spacing: 0;
  color: #8C979A;
}

.btn.btn-tertiary:disabled {
  color: #DADEDF;
}

.btn.btn-tertiary:active, .btn.btn-tertiary:active:focus, .btn.btn-tertiary:focus, .btn.btn-tertiary:hover {
  color: #131516;
  background-color: transparent;
  border: none;
}

.btn.btn-tertiary:active .zamicon, .btn.btn-tertiary:active:focus .zamicon, .btn.btn-tertiary:focus .zamicon, .btn.btn-tertiary:hover .zamicon {
  color: #00AEEF;
}

.btn.btn-tertiary-primary {
  color: #00AEEF;
}

.btn.btn-tertiary-primary:hover {
  color: #0D87E9;
}

.btn.btn-tertiary-primary:hover .zamicon {
  color: #0D87E9;
}

.btn.icon-left {
  padding-left: 64px;
}

.btn.icon-left .zamicon {
  position: absolute;
  left: 30px;
  top: 11px;
}

.btn .zamicon:before {
  color: inherit;
}

.btn-group.show .btn {
  background-color: #0D87E9;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.btn-group .dropdown-menu {
  background-color: #FAFAFA;
}

.btn-group .dropdown-menu a {
  text-decoration: none;
}

.btn-group .dropdown-item {
  color: #131516;
  line-height: 1.71;
  letter-spacing: 0.2px;
  min-width: 20rem;
  max-width: 25rem;
}

.btn-group .dropdown-item:hover {
  background-color: #EEEEEE;
}

.btn-group .dropdown-item .zamicon {
  padding-right: 10px;
}

.btn-group {
  margin: 0;
}

.multiple-state {
  width: 100%;
}

.multiple-state.btn-group .btn {
  text-transform: none;
}

.multiple-state.btn-group .btn.btn-primary:disabled {
  background-color: #C1C7C9;
  color: #FFFFFF;
}

.multiple-state.btn-group .btn.btn-outline-primary {
  color: #00AEEF;
}

.multiple-state.btn-group .btn.btn-outline-primary:disabled {
  color: #C1C7C9;
  border: 1px solid #C1C7C9;
}

.multiple-state.btn-group .btn:not(:first-child) {
  border-left: none;
}

.mobile-header {
  background: #00AEEF;
  padding: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
}

.mobile-header .form-group {
  display: block;
  margin: 0;
}

.mobile-header .form-group .form-control {
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.mobile-header .form-group .form-control::placeholder {
  line-height: 24px;
  font-size: 14px;
}

.mobile-header .form-group .form-control-feedback {
  top: 12px;
}

.mobile-header .input-group {
  margin: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.mobile-header .input-group .form-control {
  line-height: 1.43;
  font-size: 14px;
  padding: 14px 8px;
  background: none;
  background-color: #F2F3F4;
}

.mobile-header .input-group .input-group-prepend button,
.mobile-header .input-group .input-group-append button {
  background-color: #F2F3F4;
  border: none;
  padding: 0 16px;
}

.mobile-header .input-group .input-group-prepend button .zamicon,
.mobile-header .input-group .input-group-append button .zamicon {
  vertical-align: text-bottom;
}

.mobile-header .input-group .input-group-prepend button {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.mobile-header .input-group .input-group-append button {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.mobile-header .title-group {
  position: relative;
}

.mobile-header .title-group .title {
  padding: 8px 44px;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #FFFFFF;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mobile-header .title-group .zamicon {
  position: absolute;
  top: 12px;
  right: 8px;
  z-index: 2;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  pointer-events: none;
}

.mobile-header .title-group .zamicon::before {
  color: #FFFFFF;
}

.mobile-header .title-group .zamicon.zamicon-left {
  left: 8px;
}

.mobile-header .tab-bar {
  list-style: none;
  margin: 8px -8px -8px;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.mobile-header .tab-bar li {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.mobile-header .tab-bar li a {
  display: block;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #D3EAFE;
  text-decoration: none;
  text-align: center;
}

.mobile-header .tab-bar li a.selected {
  color: #FFFFFF;
  padding-bottom: 13px;
  border-bottom: 2px solid #FAA61A;
}

.mobile-header .tab-bar li.selected a {
  color: #FFFFFF;
  padding-bottom: 13px;
  border-bottom: 2px solid #FAA61A;
}

.mobile-footer {
  background-color: #FFFFFF;
  padding: 16px;
}

.mobile-footer .btn {
  margin-bottom: 0;
}

.nav-content {
  padding: 23px 0;
}

.nav-item {
  margin-bottom: 8px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: .3px;
  color: #131516;
}

.nav-item .zamicon {
  padding-right: 30px;
}

.navbar-header {
  border-bottom: 1px solid #DADEDF;
  padding: 16px;
}

.navbar-header__body {
  font-size: 14px;
  line-height: .93;
  letter-spacing: normal;
  color: #131516;
}

.navbar-header__title {
  color: #131516;
  font-size: 14px;
  font-weight: 500;
}

.bmd-layout-backdrop {
  background-color: rgba(50, 50, 50, 0.5);
}

.bmd-layout-container > .bmd-layout-drawer {
  width: 300px;
  transform: translateX(-310px);
}

.bmd-layout-drawer {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.bmd-layout-drawer > header .navbar-brand {
  padding-bottom: 25px;
  padding-left: 0;
}

.snackbar-content {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #FFFFFF;
  padding: 15px 12px;
  margin: 0 16px 16px;
}

.snackbar-content .btn-snackbar {
  font-weight: 500;
  text-transform: uppercase;
  padding-left: 12px;
  cursor: pointer;
}

.snackbar-content .zamicon {
  padding-right: 12px;
}

.snackbar-content .zamicon:before {
  color: #FFFFFF;
}

.snackbar-success .snackbar-content {
  background-color: #22AC47;
}

.snackbar-danger .snackbar-content {
  background-color: #AF0E0E;
}

.snackbar-info .snackbar-content {
  background-color: #131516;
}

.snackbar-info .snackbar-content .btn-snackbar {
  color: #00AEEF;
}
