@font-face{
    font-family:Roboto;
    font-style:normal;
    font-weight:400;
    src:url(/static/fonts/roboto-v16-latin-regular.eot);
    src:local("Roboto Regular"),local("Roboto-Regular"),url(/static/fonts/roboto-v16-latin-regular.eot?#iefix) format("embedded-opentype"),url(/static/fonts/roboto-v16-latin-regular.woff2) format("woff2"),url(/static/fonts/roboto-v16-latin-regular.woff) format("woff"),url(/static/fonts/roboto-v16-latin-regular.ttf) format("truetype")
}
@font-face{
    font-family:Roboto;
    font-style:italic;
    font-weight:400;
    src:url(/static/fonts/roboto-v16-latin-italic.eot);
    src:local("Roboto Italic"),local("Roboto-Italic"),url(/static/fonts/roboto-v16-latin-italic.eot?#iefix) format("embedded-opentype"),url(/static/fonts/roboto-v16-latin-italic.woff2) format("woff2"),url(/static/fonts/roboto-v16-latin-italic.woff) format("woff"),url(/static/fonts/roboto-v16-latin-italic.ttf) format("truetype")
}
@font-face{
    font-family:Roboto;
    font-style:normal;
    font-weight:700;
    src:url(/static/fonts/roboto-v16-latin-500.eot);
    src:local("Roboto Bold"),local("Roboto-Bold"),url(/static/fonts/roboto-v16-latin-500.eot?#iefix) format("embedded-opentype"),url(/static/fonts/roboto-v16-latin-500.woff2) format("woff2"),url(/static/fonts/roboto-v16-latin-500.woff) format("woff"),url(/static/fonts/roboto-v16-latin-500.ttf) format("truetype")
}
@font-face{
    font-family:Teko;
    font-style:normal;
    font-weight:400;
    src:url(/static/fonts/teko-v6-latin-regular.eot);src:local("Teko Regular"),local("Teko-Regular"),url(/static/fonts/teko-v6-latin-regular.eot?#iefix) format("embedded-opentype"),url(/static/fonts/teko-v6-latin-regular.woff2) format("woff2"),url(/static/fonts/teko-v6-latin-regular.woff) format("woff"),url(/static/fonts/teko-v6-latin-regular.ttf) format("truetype")
}
@font-face{
    font-family:Teko;
    font-style:normal;
    font-weight:500;
    src:url(/static/fonts/teko_medium-md-webfont.eot);
    src:local("Teko Medium"),local("Teko-Medium"),url(/static/fonts/teko_medium-md-webfont.eot?#iefix) format("embedded-opentype"),url(/static/fonts/teko_medium-md-webfont.woff2) format("woff2"),url(/static/fonts/teko_medium-md-webfont.woff) format("woff"),url(/static/fonts/teko_medium-md-webfont.ttf) format("truetype")
}
@font-face{
    font-family:Teko;
    font-style:normal;
    font-weight:100;
    src:url(/static/fonts/Teko-Light.eot);src:local("Teko Light"),local("Teko-Light"),url(/static/fonts/Teko-Light.eot?#iefix) format("embedded-opentype"),url(/static/fonts/Teko-Light.woff2) format("woff2"),url(/static/fonts/Teko-Light.woff) format("woff"),url(/static/fonts/Teko-Light.ttf) format("truetype")
}
@font-face {
    font-family: Zamicons;
    src: url(/static/fonts/Zamicons.eot?4cfyb8);
    src: url(/static/fonts/Zamicons.eot?4cfyb8#iefix) format('embedded-opentype'),
         url(/static/fonts/Zamicons.ttf?4cfyb8) format('truetype'),
         url(/static/fonts/Zamicons.woff?4cfyb8) format('woff'),
         url(/static/fonts/Zamicons.svg?4cfyb8#Zamicons) format('svg');
    font-weight: normal;
    font-style: normal;
}

$zamicon-font-color: #555F60;

[class^="zamicon-"], [class*=" zamicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: Zamicons !important;
    speak: none;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.zamicon-arrow-drop-down:before {
    content: "\e900";
    color: $zamicon-font-color;
}
.zamicon-arrow-drop-up:before {
    content: "\e901";
    color: $zamicon-font-color;
}
.zamicon-back:before {
    content: "\e902";
    color: $zamicon-font-color;
}
.zamicon-calendar:before {
    content: "\e903";
    color: $zamicon-font-color;
}
.zamicon-camera-success:before {
    content: "\e904";
    color: $zamicon-font-color;
}
.zamicon-camera-switch:before {
    content: "\e905";
    color: $zamicon-font-color;
}
.zamicon-camera:before {
    content: "\e906";
    color: $zamicon-font-color;
}
.zamicon-cancel-in-circle:before {
    content: "\e907";
    color: $zamicon-font-color;
}
.zamicon-cancel:before {
    content: "\e908";
    color: $zamicon-font-color;
}
.zamicon-caret-sort:before {
    content: "\e925";
    color: $zamicon-font-color;
}
.zamicon-delete:before {
    content: "\e909";
    color: $zamicon-font-color;
}
.zamicon-download:before {
    content: "\e90a";
    color: $zamicon-font-color;
}
.zamicon-edit:before {
    content: "\e90b";
    color: $zamicon-font-color;
}
.zamicon-error:before {
    content: "\e90c";
    color: $zamicon-font-color;
}
.zamicon-filter:before {
    content: "\e90d";
    color: $zamicon-font-color;
}
.zamicon-flash:before {
    content: "\e90e";
    color: $zamicon-font-color;
}
.zamicon-forum:before {
    content: "\e90f";
    color: $zamicon-font-color;
}
.zamicon-gallery:before {
    content: "\e910";
    color: $zamicon-font-color;
}
.zamicon-gift:before {
    content: "\e911";
    color: $zamicon-font-color;
}
.zamicon-hamburger:before {
    content: "\e912";
    color: $zamicon-font-color;
}
.zamicon-heart:before {
    content: "\e913";
    color: $zamicon-font-color;
}
.zamicon-inactive-user:before {
    content: "\e914";
    color: $zamicon-font-color;
}
.zamicon-location-off:before {
    content: "\e915";
    color: $zamicon-font-color;
}
.zamicon-location:before {
    content: "\e916";
    color: $zamicon-font-color;
}
.zamicon-logout:before {
    content: "\e917";
    color: $zamicon-font-color;
}
.zamicon-mic:before {
    content: "\e918";
    color: $zamicon-font-color;
}
.zamicon-overview:before {
    content: "\e919";
    color: $zamicon-font-color;
}
.zamicon-password-hide:before {
    content: "\e91a";
    color: $zamicon-font-color;
}
.zamicon-password-show:before {
    content: "\e91b";
    color: $zamicon-font-color;
}
.zamicon-plus:before {
    content: "\e91c";
    color: $zamicon-font-color;
}
.zamicon-scan:before {
    content: "\e91d";
    color: $zamicon-font-color;
}
.zamicon-search:before {
    content: "\e91e";
    color: $zamicon-font-color;
}
.zamicon-settings:before {
    content: "\e91f";
    color: $zamicon-font-color;
}
.zamicon-shopping-cart:before {
    content: "\e920";
    color: $zamicon-font-color;
}
.zamicon-success:before {
    content: "\e921";
    color: $zamicon-font-color;
}
.zamicon-table:before {
    content: "\e922";
    color: $zamicon-font-color;
}
.zamicon-three-dots:before {
    content: "\e923";
    color: $zamicon-font-color;
}
.zamicon-upload:before {
    content: "\e924";
    color: $zamicon-font-color;
}
