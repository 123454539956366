$border-color: #C6C7C7;

// Table
.table {
    font-size: 14px;
    line-height: 0.93;
  
    thead {
      th {
        color: #373D3F;
        font-weight: bold;
        background-color: $color-lighter-gray;
        border-bottom: 2px solid $color-primary;
        padding: 18px;
      }
    }
  
    tbody {
      tr {
        &:nth-of-type(even) {
          background-color: $color-lighter-gray;
        }
      }
    }
  
    tr {
      th {
        font-weight: bold;
        &:not(:last-child) {
          border-right: 1px solid $border-color;
        }
  
        span {
          &.zamicon {
            font-size: 10px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  
    td {
      border-left: 1px solid $border-color;
    }
  
    td, th {
      border-top: none;
      padding: 13px;
    }
  }
  